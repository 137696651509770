import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    expansionPanel: {
        boxShadow: 'none',
        backgroundColor: '#FAFAFA',
        '&::before': {
            display: 'none'
        }
    },
    faq: {
        margin: 0,
        '& a': {
            color: 'black',
            textDecoration: 'underline',
        }
    },
    faqTextContainer: {
        width: '100%',
        backgroundColor: '#FAFAFA',
    },
    faqText: {
        width: '60%',
        margin: '50px auto',
        '& h4': {
            color: '#000',
            margin: '20px 0'
        },
        '& .MuiExpansionPanelSummary-root': {
            margin: '20px 0',
            '& p': {
                fontWeight: 'bold'
            }
        },
        '& .MuiExpansionPanelDetails-root': {
            margin: '-30px 0 0 0'
        }
    },
    faqBtn: {
        maxHeight: 120
    },
    faqBtns: {
        maxHeight: 320,
        margin: 0
    },
    faqBtnLabel: {
        flexDirection: 'column',
    },
})

function Faq() {
    const classes = useStyles();
    const [faqType, setFaqType] = useState('employer');

    return (
        <Grid item className={classes.faq} container justify='center'>
            <Grid item className={classes.faqBtns} container spacing={5} justify='center' alignItems='center'>
                <Grid item>
                    <Button classes={{ root: classes.faqBtn, label: classes.faqBtnLabel }} onClick={() => setFaqType('employer')}>
                        <img src='/images/BlackProIcon.svg' alt='Black Pro365 Icon' />
                            Employer
                        </Button>
                </Grid>
                <Grid item>
                    <Button classes={{ root: classes.faqBtn, label: classes.faqBtnLabel }} onClick={() => setFaqType('applicant')}>
                        <img src='/images/WhiteProIcon.svg' alt='White Pro365 Icon' />
                            Applicant
                        </Button>
                </Grid>
            </Grid>

            <Grid item className={classes.faqTextContainer}>
                <Grid item className={classes.faqText}>
                    <Typography variant='h4'>Frequently Asked Questions</Typography>
                    <Typography variant='body2'>Pro365 is a unique way to connect with employers and candidates.  It help sort through the numerous amount of candidates when you have a open position. For candidates it makes looking for positions simple and easy. If you question is not answered below you can <a href="mailto:support@pro365.com?subject=Support">contact us</a>.</Typography>

                    {faqType === 'applicant' ?
                        <Grid item id='applicantFaq'>
                            <ExpansionPanel className={classes.expansionPanel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Why do some jobs say Apply instead of Chat?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        Some job listing come from external providers and their listing was not posted directly to Pro365.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel className={classes.expansionPanel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>How do I upload my resume from my computer?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        You can copy your resume to a cloud service like Google drive, One Drive, DropBox or others and then access that from your phone and download it.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel className={classes.expansionPanel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>Do I have to record a video?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        No. But recording a video and showing employers who you are can drastically improve your chances of getting to the next step.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel className={classes.expansionPanel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>Can I redo my video?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        Yes! You can delete your old videos and re-record them at any time.  Record them in better lighting or try several takes until you get the best one.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        :
                        <Grid item id='employerFaq'>
                            <ExpansionPanel className={classes.expansionPanel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Do I have to use the phone app?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        No. We have a webportal that can be used to make posting jobs and sorting through candidates easier for you. You can login <a href="https://recruit.pro365.com">here</a>.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel className={classes.expansionPanel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>What are Recomended candidates?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        Pro365 can show you cancdidates that are best suited to the jobs you have posted.  So you can easily contact them even if they haven't seen your posting.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel className={classes.expansionPanel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>How can I block a candidate</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        In the upper right of a chat message click the drop down menu to block or report a candidate.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel className={classes.expansionPanel}>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>How can I import multiple job descriptions?</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Typography>
                                        We currently do not have an automatic way for you to do this but feel free to <a href="mailto:support@pro365.com?subject=Batch Import">contact us</a> and we can help get this implimented.
                                    </Typography>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Faq