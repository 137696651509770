import React, { useState } from 'react';
import EmployerLandingPage from './employer/landing.js';
import EmployerCardsPage from './employer/cards.js';
import ApplicantLandingPage from './applicant/landing.js';
import ApplicantCardsPage from './applicant/cards.js';
import Terms from './../../components/terms';

const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);

function Home(initialMode) {
  const [mode, setMode] = useState(initialMode ? initialMode : 'applicant')
  const [view, setView] = useState('landing')
  const [isTermsOpen, setIsTermsOpen] = useState(false)

  return (
    <div className="App">
      {mode === 'employer' ?
        view === 'landing' ?
          <EmployerLandingPage isIOS={isIOS} switchMode={() => setMode('applicant')} switchView={() => setView('cards')} />
          :
          <EmployerCardsPage isIOS={isIOS} switchMode={() => setMode('applicant')} switchView={() => setView('landing')} />
        :
        view === 'landing' ?
          <ApplicantLandingPage isIOS={isIOS} switchMode={() => setMode('employer')} switchView={() => setView('cards')} />
          :
          <ApplicantCardsPage isIOS={isIOS} switchMode={() => setMode('employer')} switchView={() => setView('landing')} />
      }

      <Terms isOpen={isTermsOpen} setIsOpen={setIsTermsOpen} />
    </div>
  );
}

export default Home;
