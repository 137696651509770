import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Faq from '../components/faq';

const useStyles = makeStyles({
    header: {
        padding: '25px 40px',
        color: '#FFF',
        position: 'absolute',
        top: 0,
        '@media(max-width:500px)': {
            flexDirection: 'column',
            textAlign: 'center'
        }
    },
    headerLogo: {
        width: '8rem',
    },
    headerBtns: {
        '@media(max-width:500px)': {
            flexDirection: 'column',
            justifyContent: 'center',
            margin: 'auto'
        }
    },
    headerBtn: {
        color: '#FFF',
        borderRadius: 8
    },
    headerBtnOutlined: {
        border: '2px solid #3B66FA'
    },
    hero: {
        backgroundImage: 'url(/images/SupportHeaderBackground.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: 380,
    },
    searchBar: {
        '@media(max-width:500px)': {
            marginTop: 250
        }
    },
    searchBarTextField: {
        height: 24,
        width: '100%',
        margin: '15px auto'
    },
    searchBarHeading: {
        '@media(max-width:500px)': {
            fontSize: 20
        }
    },
    searchBarInput: {
        backgroundColor: '#FFF',
        color: '#3B66FA',
        borderRadius: 24,
        height: 40,
        width: '40%',
        margin: 'auto',
        '&:hover': {
            backgroundColor: '#AAA'
        },
        '& .MuiFilledInput-input': {
            padding: 20
        },
        '@media(max-width:500px)': {
            width: '90%'
        }
    },
    searchBarFocused: {
        backgroundColor: '#FFF !important'
    },
    searchIcon: {
        color: '#3B66FA',
    }
})

function Support() {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.header} xs={12} container justify='space-between'>
                <Grid item xs={12} sm={4}>
                    <Link to='/applicant'>
                        <img className={classes.headerLogo} src='/images/pro365logo.svg' alt='Pro365 Logo' />
                    </Link>
                </Grid>

                <Grid item xs={12} sm={8} className={classes.headerBtns} container justify='flex-end' spacing={5}>
                    <Grid item>
                        <Link to='/applicant'>
                            <Button className={classes.headerBtn}>Applicant</Button>
                        </Link>
                    </Grid>

                    <Grid item>
                        <Link to='/employer'>
                            <Button className={classes.headerBtn}>Employer</Button>
                        </Link>
                    </Grid>

                    <Grid item>
                        <a href="https://recruit.pro365.com">
                            <Button className={`${classes.headerBtn} ${classes.headerBtnOutlined}`} variant='outlined'>Sign In</Button>
                        </a>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item className={classes.hero} container>
                <Grid container className={classes.searchBar} justify='center' alignContent='center'>
                    <Typography variant='h4' className={classes.searchBarHeading}>Welcome to Pro365 Support</Typography>
                    {/* <TextField
                        variant='filled'
                        placeholder='How can we help you?'
                        className={classes.searchBarTextField}
                        fullWidth
                        InputProps={{
                            classes: {
                                root: classes.searchBarInput,
                                focused: classes.searchBarFocused
                            },
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment>
                                    <SearchIcon className={classes.searchIcon} />
                                </InputAdornment>
                            )
                        }}
                    /> */}
                </Grid>
            </Grid>
            <Faq />
        </Grid>
    );
}

export default Support