import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppStores from '../../../components/appStores';
import '../../../style.css';

const EmployerLandingPage = ({ isIOS, switchMode, switchView }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      switchView()
    }, 15000)

    return (() => clearTimeout(timer))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const login = () => {
    window.location.href = 'https://recruit.pro365.com'
  }

  return (
    <div className="showcase">
      <div className="video-container">
        <video src="images/employer.mp4" autoPlay muted loop playsInline={isIOS}></video>
      </div>
      <div className="showcase-top">
        <img src="images/pro365-logo.png" alt="Pro365 Logo" />
        <button className="btn-login" onClick={login}>Login</button>
      </div>

      <div className="showcase-content">
        <h1>Perfect Match For Your Company</h1>
        <h2>
          There are millions of potential excellent candidates out there.
          <br />
          We help you find the best possible additions.
        </h2>
        <div className="btnOptions">
          <Link to='/applicant'>
            <button className="btn">Applicant</button>
          </Link>
          <button className="btn-active">Employer</button>
        </div>
      </div>
      <AppStores mode='employer' />
    </div>
  )
}

export default EmployerLandingPage