import React from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Home from './pages/home/home.js';
import Support from './pages/support.js';

function App() {
  return (
    <BrowserRouter>
      <Route path='/' exact><Redirect to='/applicant' /></Route>

      <Route path='/applicant' component={() => Home("applicant")} />
      <Route path='/employer' component={() => Home("employer")} />

      <Route path='/support' component={Support} />
    </BrowserRouter>
  );
}

export default App;
