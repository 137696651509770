import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 3
  },

  apple: {
    width: 136
  },

  google: {
    width: 150
  }
})

const AppStores = ({mode}) => {
  const classes = useStyles()

  const appleLink = mode === 'employer' ?  'https://apps.apple.com/us/app/id1486579546' : 'https://apps.apple.com/us/app/id1485983976';
  const googleLink = mode === 'employer' ? 'https://play.google.com/store/apps/details?id=com.pro365.employer' : 'https://play.google.com/store/apps/details?id=com.pro365.applicant';

  return (
    <Grid className={classes.root} container justify='center' spacing={1}>
      <Grid className={classes.apple} item >
        <a href={appleLink} target="_blank" rel="noopener noreferrer">
          <img src="./images/apple store.png" alt="apple app store" />
        </a>
      </Grid>
      <Grid className={classes.google} item>
        <a href={googleLink} target="_blank" rel="noopener noreferrer">
          <img src="./images/google store.png" alt="google play store" />
        </a>
      </Grid>
    </Grid>
  )
}

export default AppStores