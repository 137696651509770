import React, { useState, useEffect } from 'react';

import AppStores from '../../../components/appStores';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import ForumIcon from '@material-ui/icons/Forum'; //communicate
import GroupIcon from '@material-ui/icons/Group'; //together
import VideocamIcon from '@material-ui/icons/Videocam'; //video
import MapIcon from '@material-ui/icons/Map'; //map

const cardSwitchAnimationDuration = 150

const useStyle = makeStyles({
    backgroundGradient: {
        background: 'linear-gradient(to top, rgb(0, 3, 13) 0%, rgb(33, 2, 92) 40%, rgb(65, 1, 172) 80%, rgb(81, 0, 211) 100%)',
        opacity: .9,
        position: 'fixed',
        top: 0,
        left: 0,
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '100vh',
        zIndex: 0
    },

    backgroundVideo: {
        position: 'fixed',
        minHeight: '100%',
        minWidth: '100%',
        top: 0,
        zIndex: -5,
        ['@media (max-width:325px)']: { // eslint-disable-line no-useless-computed-key

        }
    },

    blueCard: {
        background: 'linear-gradient(#3B66FA, #1E337D)',
        color: 'white'
    },

    card: {
        width: '50vw',
        margin: 'auto',
        textAlign: 'center',
        borderRadius: 24,
        boxShadow: '0 40px 40px 0 rgba(0, 0, 0, 0.4)',
        minHeight: '60vh',
        maxHeight: '60vh',
        padding: '5% 10%',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 4,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '60vw',
            padding: '5%',
            overflow: 'auto'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '80vw',
            padding: '10% 5%'
        }
    },

    fakeCard: {
        position: 'absolute',
        borderRadius: 24,
        minHeight: '60vh',
        maxHeight: '60vh'
    },

    fakeCardLarge: {
        width: '40vw',
        top: 15,
        left: '5vw',
        zIndex: 3,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '50vw',
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '70vw'
        }
    },

    fakeCardSmall: {
        width: '30vw',
        top: 30,
        left: '10vw',
        zIndex: 2,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '40vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '60vw'
        }
    },

    fakeCardHidden: {
        width: '20vw',
        top: 45,
        left: '15wv',
        zIndex: 1,
        opacity: 0,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '30vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '50vw'
        }
    },

    header: {
        fontSize: '1.5rem',
        fontWeight: '600',
        margin: 10,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '1rem'
        },
    },

    icon: {
        fontSize: '6rem',
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '5rem'
        }
    },

    root: {
        height: '100vh',
        overflow: 'hidden',
        marginTop: '5%'
    },

    subHeader: {
        fontSize: '1.1rem',
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '1rem'
        },
        ['@media (max-width:425)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '.6rem'
        }
    },

    tabsContainer: {
        width: '50vw',
        display: 'flex',
        justifyContent: 'flex-end',
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '60vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '80vw'
        }
    },

    tab: {
        width: 20,
        minWidth: 20,
        maxWidth: 20,
        margin: 10,
        transition: 'transform .6s, color .6s',
        '&.Mui-selected': {
            color: '#DDD',
            opacity: 1
        },
        '&:hover': {
            transform: 'translate(0, -10px)',
            color: '#FFF',
            opacity: 1
        }
    },

    tag: {
        position: 'absolute',
        top: 20,
        left: 20,
        fontWeight: '600'
    },

    yellowCard: {
        background: 'linear-gradient(#FFBE73, #D57000)'
    },

    //animations

    fadeOutMain: {
        animationName: '$fadeOutMain',
        animationDuration: cardSwitchAnimationDuration,
        top: -15,
        opacity: 0
    },
    '@keyframes fadeOutMain': {
        from: { top: 0, opacity: 1 },
        to: { top: -15, opacity: 0 }
    },

    fromLargeToMain: {
        animationName: '$fromLargeToMain',
        animationDuration: cardSwitchAnimationDuration,
        width: '50vw',
        left: 0,
        top: 0,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fromLargeToMain1024',
            width: '60vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fromLargeToMain425',
            width: '80vw'
        }
    },
    '@keyframes fromLargeToMain': {
        from: { width: '40vw', left: '5vw', top: 15 },
        to: { width: '50vw', left: 0, top: 0 }
    },
    '@keyframes fromLargeToMain1024': {
        from: { width: '50vw', left: '5vw', top: 15 },
        to: { width: '60vw', left: 0, top: 0 }
    },
    '@keyframes fromLargeToMain425': {
        from: { width: '70vw', left: '5vw', top: 15 },
        to: { width: '80vw', left: 0, top: 0 }
    },


    fromSmallToLarge: {
        animationName: '$fromSmallToLarge',
        animationDuration: cardSwitchAnimationDuration,
        width: '40vw',
        left: '5vw',
        top: 15,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fromSmallToLarge1024',
            width: '50vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fromSmallToLarge425',
            width: '70vw'
        }
    },
    '@keyframes fromSmallToLarge': {
        from: { width: '30vw', left: '10vw', top: 30 },
        to: { width: '40vw', left: '5vw', top: 15 }
    },
    '@keyframes fromSmallToLarge1024': {
        from: { width: '40vw', left: '10vw', top: 30 },
        to: { width: '50vw', left: '5vw', top: 15 }
    },
    '@keyframes fromSmallToLarge425': {
        from: { width: '60vw', left: '10vw', top: 30 },
        to: { width: '70vw', left: '5vw', top: 15 }
    },

    fadeInSmall: {
        animationName: '$fadeInSmall',
        animationDuration: cardSwitchAnimationDuration,
        opacity: 1,
        width: '30vw',
        left: '10vw',
        top: 30,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fadeInSmall1024',
            width: '40vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fadeInSmall425',
            width: '60vw'
        }
    },
    '@keyframes fadeInSmall': {
        from: { opacity: 0, width: '20vw', left: '15vw', top: 45 },
        to: { opacity: 1, width: '30vw', left: '10vw', top: 30 }
    },
    '@keyframes fadeInSmall1024': {
        from: { opacity: 0, width: '30vw', left: '15vw', top: 45 },
        to: { opacity: 1, width: '40vw', left: '10vw', top: 30 }
    },
    '@keyframes fadeInSmall425': {
        from: { opacity: 0, width: '50vw', left: '15vw', top: 45 },
        to: { opacity: 1, width: '60vw', left: '10vw', top: 30 }
    }
})

const ApplicantCardsPage = ({ switchView, isIOS }) => {
    const classes = useStyle()
    const [tabNumber, setTabNumber] = useState(0)
    const [currentTimeout, setCurrentTimeout] = useState(null)

    useEffect(() => {
        clearTimeout(currentTimeout)
        setCurrentTimeout(setTimeout(() => {
            changeTab()
        }, 5000))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabNumber])

    const changeView = () => {
        clearTimeout(currentTimeout)
        setCurrentTimeout(null)
        setTabNumber(0)
        switchView()
    }

    const onTabChange = (event, newValue) => {
        setTabNumber(newValue)
    }

    const changeTab = () => {
        if (tabNumber === 3) {
            setTabNumber(0)
        } else {
            const card = document.querySelector(`.${classes.card}`)
            if (card) {
                card.classList.add(classes.fadeOutMain)
            }

            const fakeCardLarge = document.querySelector(`.${classes.fakeCardLarge}`)
            if (fakeCardLarge) {
                fakeCardLarge.classList.add(classes.fromLargeToMain)
            }

            const fakeCardSmall = document.querySelector(`.${classes.fakeCardSmall}`)
            if (fakeCardSmall) {
                fakeCardSmall.classList.add(classes.fromSmallToLarge)
            }

            const fakeCardHidden = document.querySelector(`.${classes.fakeCardHidden}`)
            if (fakeCardHidden) {
                fakeCardHidden.classList.add(classes.fadeInSmall)
            }

            setTimeout(() => {
                setTabNumber(tabNumber + 1)
            }, cardSwitchAnimationDuration)
        }
    }

    return (
        <Grid className={classes.root} container direction='column' justify='flex-start' alignItems='center'>
            <div className={classes.backgroundGradient} onClick={changeView}> </div>
            <video src='images/applicant.mp4' autoPlay muted loop playsInline={isIOS} className={classes.backgroundVideo} />

            <Grid item className={classes.tabsContainer}>
                <Tabs TabIndicatorProps={{ style: { background: 'none' } }} className={classes.tabs} value={tabNumber} onChange={onTabChange}>
                    <Tab disableRipple className={classes.tab} icon={<ForumIcon />} />
                    <Tab disableRipple className={classes.tab} icon={<GroupIcon />} />
                    <Tab disableRipple className={classes.tab} icon={<VideocamIcon />} />
                    <Tab disableRipple className={classes.tab} icon={<MapIcon />} />
                </Tabs>
            </Grid>

            {tabNumber === 0 ?
                <Grid item style={{ position: 'relative' }}>
                    <Card className={`${classes.card} ${classes.blueCard} ${classes.firstCards}`}>
                        <ForumIcon className={classes.icon} />
                        <Typography variant='h1' className={classes.header}>Communicate Seamlessly</Typography>
                        <Typography variant='subtitle1' className={classes.subHeader}>With our built in chat interface you can easily talk directly to employers. Once an employer accepts your application you can begin chatting seamlessly. This feature will save you time while talking to employers to make sure its the best fit for you.</Typography>
                        <Typography variant='caption' className={classes.tag}>Easy Communication</Typography>
                    </Card>
                    <div className={`${classes.fakeCard} ${classes.fakeCardLarge} ${classes.yellowCard}`} />
                    <div className={`${classes.fakeCard} ${classes.fakeCardSmall} ${classes.blueCard}`} />
                    <div className={`${classes.fakeCard} ${classes.fakeCardHidden} ${classes.yellowCard}`} />
                </Grid>
                : tabNumber === 1 ?
                    <Grid item style={{ position: 'relative' }}>
                        <Card className={`${classes.card} ${classes.yellowCard}`}>
                            <CardContent>
                                <GroupIcon className={classes.icon} style={{ color: '#3c66fa' }} />
                                <Typography variant='h1' className={classes.header} style={{ color: 'black' }}>The Perfect Fit</Typography>
                                <Typography variant='subtitle1' className={classes.subHeader}>Our matching system allows you to see which potential jobs are best suited for you. By inputing your skills and experience our matching system will give you a percentage rating toward finding the right job.</Typography>
                                <Typography variant='caption' className={classes.tag} style={{ color: 'black' }}>Job Matching</Typography>
                            </CardContent>
                        </Card>
                        <div className={`${classes.fakeCard} ${classes.fakeCardLarge} ${classes.blueCard}`} />
                        <div className={`${classes.fakeCard} ${classes.fakeCardSmall} ${classes.yellowCard}`} />
                    </Grid>
                    : tabNumber === 2 ?
                        <Grid item style={{ position: 'relative' }}>
                            <Card className={`${classes.card} ${classes.blueCard}`}>
                                <CardContent>
                                    <VideocamIcon className={classes.icon} />
                                    <Typography variant='h1' className={classes.header}>Best Foot Forward</Typography>
                                    <Typography variant='subtitle1' className={classes.subHeader}>Put your best foot forward with our pre-screen video introduction. Allow employers to view your video and get to know the real you. Employers will view your video while you are taking care of business, saving you time in the job hunt.</Typography>
                                    <Typography variant='caption' className={classes.tag}>Get More Views</Typography>
                                </CardContent>
                            </Card>
                            <div className={`${classes.fakeCard} ${classes.fakeCardLarge} ${classes.yellowCard}`} />
                        </Grid>
                        :
                        <Grid item style={{ position: 'relative' }}>
                            <Card className={`${classes.card} ${classes.yellowCard}`}>
                                <CardContent>
                                    <MapIcon className={classes.icon} style={{ color: '#3c66fa' }} />
                                    <Typography variant='h1' className={classes.header} style={{ color: 'black' }}>Less Work</Typography>
                                    <Typography variant='subtitle1' className={classes.subHeader}>The map feature allows you make a match based on what’s nearby. Prioritize specific employers in a dedicated radius to your location. This feature will allow you to easily swipe through employers in your area to find the best potential fit.</Typography>
                                    <Typography variant='caption' className={classes.tag} style={{ color: 'black' }}>Find Jobs Easier</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
            }
            <AppStores />
        </Grid>
    )
}

export default ApplicantCardsPage