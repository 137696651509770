import React, { useState, useEffect } from 'react';

import AppStores from '../../../components/appStores';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import AccessTimeIcon from '@material-ui/icons/AccessTime'; //clock
import SelectAllIcon from '@material-ui/icons/SelectAll'; // square
import WorkIcon from '@material-ui/icons/Work'; //briefcase
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined'; //thumb

const cardSwitchAnimationDuration = 150

const useStyle = makeStyles({
    backgroundGradient: {
        background: 'linear-gradient(to top, rgb(0, 3, 13) 0%, rgb(33, 2, 92) 40%, rgb(65, 1, 172) 80%, rgb(81, 0, 211) 100%)',
        opacity: .9,
        position: 'fixed',
        top: 0,
        left: 0,
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '100vh',
        zIndex: 0
    },

    backgroundVideo: {
        position: 'fixed',
        minHeight: '100%',
        minWidth: '100%',
        top: 0,
        zIndex: -5,
        ['@media (max-width:325px)']: { // eslint-disable-line no-useless-computed-key

        }
    },

    blueCard: {
        background: 'linear-gradient(#3B66FA, #1E337D)',
        color: 'white'
    },

    card: {
        width: '50vw',
        margin: 'auto',
        textAlign: 'center',
        borderRadius: 24,
        boxShadow: '0 40px 40px 0 rgba(0, 0, 0, 0.4)',
        minHeight: '60vh',
        maxHeight: '60vh',
        padding: '5% 10%',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 4,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '60vw',
            padding: '5%',
            overflow: 'auto'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '80vw',
            padding: '10% 5%'
        }
    },

    fakeCard: {
        position: 'absolute',
        borderRadius: 24,
        minHeight: '60vh',
        maxHeight: '60vh'
    },

    fakeCardLarge: {
        width: '40vw',
        top: 15,
        left: '5vw',
        zIndex: 3,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '50vw',
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '70vw'
        }
    },

    fakeCardSmall: {
        width: '30vw',
        top: 30,
        left: '10vw',
        zIndex: 2,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '40vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '60vw'
        }
    },

    fakeCardHidden: {
        width: '20vw',
        top: 45,
        left: '15wv',
        zIndex: 1,
        opacity: 0,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '30vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '50vw'
        }
    },

    header: {
        fontSize: '1.5rem',
        fontWeight: '600',
        margin: 10,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '1rem'
        },
    },

    icon: {
        fontSize: '6rem',
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '5rem'
        }
    },

    root: {
        height: '100vh',
        overflow: 'hidden',
        marginTop: '5%'
    },

    subHeader: {
        fontSize: '1.1rem',
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '1rem'
        },
        ['@media (max-width:425)']: { // eslint-disable-line no-useless-computed-key
            fontSize: '.6rem'
        }
    },

    tabsContainer: {
        width: '50vw',
        display: 'flex',
        justifyContent: 'flex-end',
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            width: '60vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            width: '80vw'
        }
    },

    tab: {
        width: 20,
        minWidth: 20,
        maxWidth: 20,
        margin: 10,
        transition: 'transform .6s, color .6s',
        '&.Mui-selected': {
            color: '#DDD',
            opacity: 1
        },
        '&:hover': {
            transform: 'translate(0, -10px)',
            color: '#FFF',
            opacity: 1
        }
    },

    tag: {
        position: 'absolute',
        top: 20,
        left: 20,
        fontWeight: '600'
    },

    yellowCard: {
        background: 'linear-gradient(#FFBE73, #D57000)'
    },

    //animations

    fadeOutMain: {
        animationName: '$fadeOutMain',
        animationDuration: cardSwitchAnimationDuration,
        top: -15,
        opacity: 0
    },
    '@keyframes fadeOutMain': {
        from: { top: 0, opacity: 1 },
        to: { top: -15, opacity: 0 }
    },

    fromLargeToMain: {
        animationName: '$fromLargeToMain',
        animationDuration: cardSwitchAnimationDuration,
        width: '50vw',
        left: 0,
        top: 0,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fromLargeToMain1024',
            width: '60vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fromLargeToMain425',
            width: '80vw'
        }
    },
    '@keyframes fromLargeToMain': {
        from: { width: '40vw', left: '5vw', top: 15 },
        to: { width: '50vw', left: 0, top: 0 }
    },
    '@keyframes fromLargeToMain1024': {
        from: { width: '50vw', left: '5vw', top: 15 },
        to: { width: '60vw', left: 0, top: 0 }
    },
    '@keyframes fromLargeToMain425': {
        from: { width: '70vw', left: '5vw', top: 15 },
        to: { width: '80vw', left: 0, top: 0 }
    },


    fromSmallToLarge: {
        animationName: '$fromSmallToLarge',
        animationDuration: cardSwitchAnimationDuration,
        width: '40vw',
        left: '5vw',
        top: 15,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fromSmallToLarge1024',
            width: '50vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fromSmallToLarge425',
            width: '70vw'
        }
    },
    '@keyframes fromSmallToLarge': {
        from: { width: '30vw', left: '10vw', top: 30 },
        to: { width: '40vw', left: '5vw', top: 15 }
    },
    '@keyframes fromSmallToLarge1024': {
        from: { width: '40vw', left: '10vw', top: 30 },
        to: { width: '50vw', left: '5vw', top: 15 }
    },
    '@keyframes fromSmallToLarge425': {
        from: { width: '60vw', left: '10vw', top: 30 },
        to: { width: '70vw', left: '5vw', top: 15 }
    },

    fadeInSmall: {
        animationName: '$fadeInSmall',
        animationDuration: cardSwitchAnimationDuration,
        opacity: 1,
        width: '30vw',
        left: '10vw',
        top: 30,
        ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fadeInSmall1024',
            width: '40vw'
        },
        ['@media (max-width:425px)']: { // eslint-disable-line no-useless-computed-key
            animationName: '$fadeInSmall425',
            width: '60vw'
        }
    },
    '@keyframes fadeInSmall': {
        from: { opacity: 0, width: '20vw', left: '15vw', top: 45 },
        to: { opacity: 1, width: '30vw', left: '10vw', top: 30 }
    },
    '@keyframes fadeInSmall1024': {
        from: { opacity: 0, width: '30vw', left: '15vw', top: 45 },
        to: { opacity: 1, width: '40vw', left: '10vw', top: 30 }
    },
    '@keyframes fadeInSmall425': {
        from: { opacity: 0, width: '50vw', left: '15vw', top: 45 },
        to: { opacity: 1, width: '60vw', left: '10vw', top: 30 }
    }
})


const EmployerCardsPage = ({ switchView, isIOS }) => {
    const classes = useStyle()
    const [tabNumber, setTabNumber] = useState(0)
    const [currentTimeout, setCurrentTimeout] = useState(null)

    useEffect(() => {
        clearTimeout(currentTimeout)
        setCurrentTimeout(setTimeout(() => {
            changeTab()
        }, 5000))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabNumber])

    const changeView = () => {
        clearTimeout(currentTimeout)
        setCurrentTimeout(null)
        setTabNumber(0)
        switchView()
    }

    const onTabChange = (event, newValue) => {
        setTabNumber(newValue)
    }

    const changeTab = () => {
        if (tabNumber === 3) {
            setTabNumber(0)
        } else {
            const card = document.querySelector(`.${classes.card}`)
            if (card) {
                card.classList.add(classes.fadeOutMain)
            }

            const fakeCardLarge = document.querySelector(`.${classes.fakeCardLarge}`)
            if (fakeCardLarge) {
                fakeCardLarge.classList.add(classes.fromLargeToMain)
            }

            const fakeCardSmall = document.querySelector(`.${classes.fakeCardSmall}`)
            if (fakeCardSmall) {
                fakeCardSmall.classList.add(classes.fromSmallToLarge)
            }

            const fakeCardHidden = document.querySelector(`.${classes.fakeCardHidden}`)
            if (fakeCardHidden) {
                fakeCardHidden.classList.add(classes.fadeInSmall)
            }

            setTimeout(() => {
                setTabNumber(tabNumber + 1)
            }, cardSwitchAnimationDuration)
        }
    }

    return (
        <Grid className={classes.root} container direction='column' justify='flex-start' alignItems='center' >
            <div className={classes.backgroundGradient} onClick={changeView}> </div>
            <video src='images/employer.mp4' autoPlay muted loop className={classes.backgroundVideo} playsInline={isIOS} />

            <Grid item className={classes.tabsContainer}>
                <Tabs TabIndicatorProps={{ style: { background: 'none' } }} className={classes.tabs} value={tabNumber} onChange={onTabChange}>
                    <Tab disableRipple className={`${classes.tab} ${classes.clockIcon}`} icon={<AccessTimeIcon />} />
                    <Tab disableRipple className={classes.tab} icon={<SelectAllIcon />} />
                    <Tab disableRipple className={classes.tab} icon={<WorkIcon />} />
                    <Tab disableRipple className={classes.tab} icon={<ThumbUpOutlinedIcon />} />
                </Tabs>
            </Grid>

            {tabNumber === 0 ?
                <Grid item style={{ position: 'relative' }}>
                    <Card className={`${classes.card} ${classes.blueCard}`}>
                        <AccessTimeIcon className={classes.icon} />
                        <Typography variant='h1' className={classes.header}>Bring In The Right Candidates</Typography>
                        <Typography variant='subtitle1' className={classes.subHeader}>With productive and time-saving features like the built-in chat platform, the map feature, the pre-screened video, matching system, quick pre-screening from multiple employers at one time, and more, employers can find better hires faster.</Typography>
                        <Typography variant='caption' className={classes.tag}>Save Time</Typography>
                    </Card>
                    <div className={`${classes.fakeCard} ${classes.fakeCardLarge} ${classes.yellowCard}`} />
                    <div className={`${classes.fakeCard} ${classes.fakeCardSmall} ${classes.blueCard}`} />
                    <div className={`${classes.fakeCard} ${classes.fakeCardHidden} ${classes.yellowCard}`} />
                </Grid>
                : tabNumber === 1 ?
                    <Grid item style={{ position: 'relative' }}>
                        <Card className={`${classes.card} ${classes.yellowCard}`}>
                            <CardContent>
                                <SelectAllIcon className={classes.icon} style={{ color: '#3c66fa' }} />
                                <Typography variant='h1' className={classes.header} style={{ color: 'black' }}>Pre-Screen Applicant</Typography>
                                <Typography variant='subtitle1' className={classes.subHeader}>By pre-screening applicants you can really get a feel for who your applicants are. With our pre-screener questions you can review their answers and see if they are a good fit for your company.</Typography>
                                <Typography variant='caption' className={classes.tag} style={{ color: 'black' }}>Pre-Screen</Typography>
                            </CardContent>
                        </Card>
                        <div className={`${classes.fakeCard} ${classes.fakeCardLarge} ${classes.blueCard}`} />
                        <div className={`${classes.fakeCard} ${classes.fakeCardSmall} ${classes.yellowCard}`} />
                    </Grid>
                    : tabNumber === 2 ?
                        <Grid item style={{ position: 'relative' }}>
                            <Card className={`${classes.card} ${classes.blueCard}`}>
                                <CardContent>
                                    <WorkIcon className={classes.icon} />
                                    <Typography variant='h1' className={classes.header}>Identify Top Talent</Typography>
                                    <Typography variant='subtitle1' className={classes.subHeader}>Finding the best talent is a breeze. Our app ensures every candidate represents their real qualities - candidates who can carry their excellent resume to a quality interview.</Typography>
                                    <Typography variant='caption' className={classes.tag}>Improve Hiring</Typography>
                                </CardContent>
                            </Card>
                            <div className={`${classes.fakeCard} ${classes.fakeCardLarge} ${classes.yellowCard}`} />
                        </Grid>
                        :
                        <Grid item style={{ position: 'relative' }}>
                            <Card className={`${classes.card} ${classes.yellowCard}`}>
                                <CardContent>
                                    <ThumbUpOutlinedIcon className={classes.icon} style={{ color: '#3c66fa' }} />
                                    <Typography variant='h1' className={classes.header} style={{ color: 'black' }}>Superior Candidate Matching</Typography>
                                    <Typography variant='subtitle1' className={classes.subHeader}>Candidates prioritize top traits and accomplishments. Look for the skills necessary for a job highlighted in the free recruitment app instead of manually reading and reviewing every single resume for perinent information hidden within.</Typography>
                                    <Typography variant='caption' className={classes.tag} style={{ color: 'black' }}>Match Rating</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
            }
            <AppStores />
        </Grid>
    )
}

export default EmployerCardsPage