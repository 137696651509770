import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    closeButton: {
        position: 'fixed',
        right: 'calc(5% + 10px)',
        top: 'calc(5% + 10px)'
    },

    container: {
        textAlign: 'center',
        width: '90%',
        minHeight: '90vh',
        maxHeight: '90vh',
        position: 'absolute',
        top: '5%',
        left: '5%',
        outline: 'none',
        borderRadius: 24,
        background: 'linear-gradient(to top, rgb(0, 3, 13) 0%, rgb(33, 2, 92) 40%, rgb(65, 1, 172) 80%, rgb(81, 0, 211) 100%)',
        opacity: .95,
        padding: 40,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    }
})

const Terms = (props) => {
    const classes = useStyles()
    const { isOpen, setIsOpen } = props

    return (
        <Modal open={isOpen}>
            <div className={classes.container}>
                <IconButton className={classes.closeButton} onClick={() => setIsOpen(false)}>
                    <CloseIcon />
                </IconButton>
                <h1>PRO365 TERMS OF USE</h1>
                <p>
                    <br />                PLEASE READ THESE TERMS OF USE CAREFULLY.
<br />
                    <br />PRO365 is a mobile application intended for jobseekers seeking to upload resumes, personal information and video introductions, as well as for employers actively seeking job candidates. PRO365 is not a hiring or staffing company and does not act as a recruiter. PRO365 simply seeks to connect jobseekers with potential employers and recruiters. These Terms of Use apply to the PRO365 Application (the “App”). This App is owned by PRO365, LLC (collectively, “PRO365,” “we” or “us”).
<br />
                    <br />You should carefully read these Terms of Use. Your access to and use of the App is governed by these Terms of Use, which are a legally binding contract between you and PRO365. In addition, when using a particular service and/or accessing certain materials on or through the App, you will be subject to any posted terms, conditions and rules applicable to the service and/or materials, which are incorporated into these Terms of Use and govern any conflict or inconsistency with these Terms of Use. Your use of the App is also governed by our Privacy Policy.
<br />
                    <br />If you downloaded this App from Apple, Inc. ("Apple") (e.g. through iTunes or the Mac App Store), you acknowledge that these Terms of Use are concluded between you and PRO365 only, and not with Apple. PRO365, and not Apple, is solely responsible for the App and the content thereof.
<br />
                    <br />IF YOU DO NOT UNDERSTAND, ACCEPT AND AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THESE TERMS OF USE, YOU SHOULD NOT DOWNLOAD OR OTHERWISE USE THE APP.
<br />
                    <br />AMENDMENTS TO TERMS OF USE
<br />PRO365 reserves the right to change the terms and conditions of these Terms of Use, the Privacy Policy and/or any of its other terms, conditions and rules relating to the App, at any time in its sole discretion. PRO365 shall notify you of such changes. Continued use of the App after any such changes have been posted shall constitute your agreement to them.
<br />
                    <br />THIRD-PARTY SERVICE PROVIDERS
<br />PRO365 may use third-party service providers to help develop and maintain this App, to provide specific services offered through this App, and to help service your Account. You agree that the terms and conditions set forth in these Terms of Use, including all disclaimers of warranties and limitations of liability, inure to the benefit of any third-party service providers engaged by PRO365. All references to PRO365 are deemed to include its agents and service providers. All agents and service providers of PRO365 will be authorized to use your personal information only for the purpose for which they are hired.
<br />
                    <br />APP MATERIALS, SERVICES AND SOFTWARE
<br />Through this App, PRO365 may make available to you: (a) certain audio and visual articles, text, information, data, images, illustrations, photographs, video, documents and other materials contained or displayed in or made available through the App (collectively, "Materials"); and (b) various services and functionality (collectively, the "App"). In these Terms of Use, all references to the "App" shall be deemed to include all "Software" and "Materials," unless otherwise expressly indicated.
<br />
                    <br />The App design, text and graphics, and the selection and arrangement of such elements are copyrighted and are protected by worldwide copyright laws and treaty provisions. Unless otherwise indicated, all product and service marks and logos displayed on the App are subject to the trademark rights of PRO365, its affiliates, or its business partners. You shall not: (a) remove or destroy any proprietary rights marks or legends on or in the App; (b) modify, enhance, adapt, translate, or create derivative works of the App; (c) republish, post, transmit, transfer, distribute, assign, sublicense, rent, lease or sell the App; (d) decompile, disassemble or reverse engineer the App; (e) reproduce or make copies of the App; (f) "frame" or "mirror" the App on any other server or Internet-based device; and/or (g) access, view, download, print, use and/or display the App for any commercial or other money-making purpose; (h) download or copy Account information for the benefit of another merchant; (i) use any data mining, robots, or similar data gathering and extraction tools; or (j) use any meta tags or any other "hidden text" utilizing PRO365's name or trademarks without our express written consent. You acknowledge that certain elements of the App are, or may in the future be, licensed to PRO365 by third parties and that the availability of such elements may cease automatically, without notice or liability on the part of PRO365.
<br />
                    <br />Our trademarks cannot be used without an express, written license agreement. Our trademarks and trade dress may not be used in connection with any product or service that is not ours, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits PRO365. All other trademarks not owned by PRO365 that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by PRO365.
<br />
                    <br />PRO365 and/or its licensors own and shall retain all rights, title and interests, including all intellectual property rights, in and to the App, and all elements thereof. Except for the express licenses granted to you herein, you neither have nor acquire any rights, title or interests in or to the App, or any element thereof.
<br />
                    <br />RIGHT TO USE BY USERS WHO ARE JOB SEEKERS
<br />PRO365 and its licensors grant to you a personal, non-exclusive, non-transferable right to: (a) access and view the Materials; and (b) use the tools and software on the App (collectively, "Software") solely for your own informational, financial and non-commercial use seeking employment opportunities for yourself on a mobile device that you own or control, in accordance with these Terms of Use and as permitted by the Usage Rules set forth in the Apple App Store Terms and Conditions.  All rights not expressly granted by PRO365 to you are retained by PRO365, and you may not use the App and/or any element of the App in any manner or for any purpose not expressly authorized by these Terms of Use. The rights granted to you do not include, and are not applicable to, the design or layout of the App, which are protected by trade dress and other laws and may not be copied or imitated in whole or in part.
<br />
                    <br />RIGHT TO USE BY USERS WHO ARE EMPLOYERS OR RECRUITERS
<br />PRO365 and its licensors grant to you a personal, terminable, non-exclusive, non-transferable right to: (a) access and view the Materials; and (b) use the Software solely for your own internal business use seeking candidates for employment on a mobile device that you own or control, in accordance with these Terms of Use and as permitted by the Usage Rules set forth in the Apple App Store Terms and Conditions. This authorizes you to view and download copies of the material on the App solely for your personal use directly related to searching for and recruiting job prospects. PRO365 also grants you a personal, terminable, non-exclusive, non-transferable right to use the Materials and Software for your internal use only. You may not sell, transfer or assign any of the Materials or Software or your rights to any of the Materials or Software provided by PRO365 to any third party without the express written authorization of PRO365. All rights not expressly granted by PRO365 to you are retained by PRO365, and you may not use the App and/or any element of the App in any manner or for any purpose not expressly authorized by these Terms of Use. The rights granted to you do not include, and are not applicable to, the design or layout of the App, which are protected by trade dress and other laws and may not be copied or imitated in whole or in part.
<br />
                    <br />APP ACCESS, REGISTRATION, AND PASSWORDS
<br />As a condition to using certain aspects of the App, you may be required to register with PRO365 and select a password and screen name ("User ID"). You shall provide PRO365 with accurate, complete, and updated registration information. Failure to do so shall constitute a breach of these Terms of Use, which may result in immediate termination of your PRO365 Account. You shall not (i) select or use as a User ID or domain a name of another person with the intent to impersonate that person; (ii) use as a User ID or domain a name subject to any rights of a person other than you without appropriate authorization; or (iii) use as a User ID or domain a name that is otherwise offensive, vulgar or obscene. We reserve the right to refuse registration of, or cancel a User ID and domain in its sole discretion.
<br />
                    <br />You are solely responsible for the security of online access to this App, including access to any user account ("Account"), and you must take precautions to protect the confidentiality of your password and User ID. You should not share your password or User ID with any other person or entity. You are solely responsible for verifying the accuracy of all transactions placed in your Account and for ensuring that you receive a confirmation for all transactions placed for your Account.
<br />
                    <br />You should contact PRO365 immediately if you suspect unauthorized use of your password or User ID, or any other unauthorized activity on your Account. You are and will be responsible for all activities conducted on and with the App that make use of your password and/or User ID, including any use you may subsequently contend was not authorized by you. PRO365 may justifiably assume that any orders or instructions received through any electronic systems and placed under your Account or password were placed or authorized by you.
<br />
                    <br />ELIGIBILITY
<br />The App is available only to individuals who are at least 14 years old.  The App is intended for individuals of a legal working age seeking employment and for employers and recruiters seeking candidates for employment opportunities. You represent and warrant that you are of legal age to form a binding contract, and that all registration information you submit is accurate and truthful. PRO365 may, in its sole discretion, refuse to offer the App to any person or entity and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the App is revoked in such jurisdictions.
<br />
                    <br />USER CONTENT: JOBSEEKERS, EMPLOYERS, AND RECRUITERS
<br />The App provides you with the ability to upload or otherwise provide information, data, text, software, photographs, graphics, messages, videos, services, and/or other materials (individually and collectively, "Content") in order to seek employment opportunities or search for and recruit job prospects. All Content is the sole responsibility of the person by whom the Content was posted. This means that you, and not us, are solely responsible for all Content that you upload, post, transmit or otherwise make available through or on this App. You are solely responsible for all Content posted under your Account. PRO365 does not control the Content posted on or through this App and, therefore, PRO365 does not guarantee the accuracy, integrity or quality of such Content. You represent and warrant that (i) you own or otherwise control all of the rights to the Content that you post; (ii) that the Content is accurate; (iii) use of the Content you supply does not violate this policy; (iv) the Content does not infringe any intellectual property or other rights of any third party and does not violate any applicable laws or regulation; and (v) the Content will not cause injury to any person or entity.
<br />
                    <br />Should Content be deemed illegal, PRO365 will cooperate with the proper authorities, including but not limited to submitting all necessary information to them. If we determine, in our sole discretion, that any Content submitted by you is offensive or inappropriate we may ask you to retract or modify the Content in question or we may terminate your Account. We reserve the right to edit or remove your Content. We have no obligation, however, to restrict or monitor Content in any way.
<br />
                    <br />You may see or read things that you do not like or agree with on our App. You understand that by using our App, you may be exposed to Content that is offensive, indecent or objectionable. Under no circumstances will we be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or for any loss or damage of any kind incurred as a result of the use of any Content posted, transmitted or otherwise made available through our App. You acknowledge that we do not have any obligation to pre-screen Content, although we reserve the right, in our sole discretion, to refuse, move, or delete any Content.
<br />
                    <br />You are solely responsible for any use or reliance on the Content, including on its accuracy, completeness, or usefulness. You acknowledge that we do not have any obligation to pre-screen Content, but that we and our designees shall have the right (but not the obligation) in our sole discretion to refuse or remove any Content that is available through our App. Without limiting the foregoing, we and our designees shall have the right to remove any Content that violates these Terms of Use or any other applicable policy or is otherwise objectionable. You agree that you must evaluate, and bear all risks associated with, the use of any Content, including any reliance on the accuracy, completeness, or usefulness of such Content.
<br />
                    <br />We do not want to receive any confidential or proprietary information from you through this App or by email. Unless otherwise agreed in writing by an authorized PRO365 employee, any material, information or idea you transmit to PRO365 or the App by any means may be disseminated or used by PRO365 without compensation or liability to you for any purpose whatsoever, including, but not limited to, developing, manufacturing and marketing products. However, this provision does not apply to personal information that is subject to our Privacy Policy.
<br />
                    <br />Rules for Content Uploads
<br />The following, non-inclusive list of rules applies to jobseekers, employers, and recruiters seeking to upload Content to the App:
<br />
                    <br />a.	Your Content as an employer or recruiter must contain sufficient detail to convey clearly to a jobseeker the nature and requirements of the job opportunity, or, in the case of users seeking employment, your qualifications as a candidate for employment. Content that encourages a user to "email for more details" are not permitted.
<br />
                    <br />b.	Job postings must describe individual openings for traditional W-2 or 1099 employees. You may not advertise multiple job openings in a single posting.
<br />
                    <br />c.	Resume postings must contain the accurate resume of a living individual seeking employment on a full-time, part-time, or contractual basis on his or her own behalf.
<br />
                    <br />PRO365 is under no obligation to monitor the Content posted on the App, but it may monitor Content at random. Content found to violate the above Rules for Content Uploads may be removed at PRO365's sole discretion.
<br />
                    <br />If we deem your uploaded Content to be in violation of these Terms of Use or your uploaded Content is otherwise inappropriate, we reserve the right to terminate your use of the App and immediately deactivate your account.
<br />
                    <br />Ownership and License
<br />PRO365 does not claim ownership of the Content you upload, place or post through this App. You are responsible for protecting your rights in such Content and are not entitled to our help in protecting such Content.
<br />
                    <br />PRO365 will not have any ownership rights in any elements of your Content; however, PRO365 requires certain licenses to provide the App.  By uploading, placing or posting Content through this App, you grant and its authorized sublicensees and distributors, if any, a perpetual, irrevocable, world-wide, royalty-free, non-exclusive and fully sublicensable license to use, distribute, reproduce, modify, adapt, translate, publicly perform, publicly display, and create derivative works from the Content (in whole or in part) and to incorporate such Content into other works in any format or medium now known or later developed, for any purpose associated with the App.
<br />
                    <br />PROHIBITED BEHAVIORS
<br />Your ability to use this App and contribute to discussions depends on your compliance with community standards and the conduct guidelines set forth in these Terms of Use, including the section "User Content," above. If you fail to conduct yourself appropriately, we may revoke your privileges to use all or a portion of this App and/or take other appropriate measures to enforce these community standards and conduct guidelines if violations are brought to our attention.
<br />
                    <br />The following is a non-inclusive list of behaviors that are not permitted on the App. You agree:
<br />
                    <br />a.	that you will not use the App for any illegal purpose or in any way that interrupts, damages or impairs the service provided by the App;
<br />b.	that you will not access or attempt to access the accounts of other users of the App;
<br />c.	that you will not impersonate any person, or misrepresent your identity or affiliation with any person;
<br />d.	not to post or transmit through the App any content which is or could reasonably be viewed as:
<br />i.	hate speech, obscene, harassing, threatening, pornographic, abusive or defamatory to an individual or group of individuals on the basis of religious belief, race, gender, age, disability or otherwise;
<br />ii.	inciting violence, or containing nudity or graphic or gratuitous violence;
<br />iii.	advertising or promoting a third party or your own products or services;
<br />iv.	an unauthorized commercial communication of any kind (including, without limitation, spam);
<br />v.	fraudulent, inaccurate or misleading, or is otherwise objectionable content of any kind;
<br />vi.	infringing or violating someone else’s rights or otherwise violates the law;
<br />vii.	identifying any person without their consent or disclosing anyone else’s personal contact details or invading their privacy, or
<br />viii.	containing software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of the App, or any computer software or hardware or telecommunications equipment.
<br />e.	that any content you post or upload to the App or otherwise make available via the App is owned by you and does not breach the requirements set out in (d)(i)-(viii) above;
<br />f.	to refrain from doing anything which is defamatory, offensive, damaging or which we believe might damage our reputation, or that of the App, a provider of services accessed through the App, or the publisher of the Material on the App (“Publisher”);
<br />g.	not to copy the App or any content on the App except where such copying is incidental to the normal non-commercial use of the App, or where it is necessary for the purpose of back-up or operational security;
<br />h.	not to make alterations to, or modifications of, the whole or any part of the App or any content on the App, or permit the App or any part of it to be combined with, or become incorporated in, any other programs;
<br />i.	not to attempt to decompile, reverse engineer, disassemble, modify, or hack this App or to defeat or overcome any encryption and/or digital rights management technology implemented by PRO365 with respect to this App and/or data transmitted, processed, or stored by PRO365 or this App;
<br />j.	not to infringe our intellectual property or the Publisher’s or our other licensors’ intellectual property in relation to your use of the App;
<br />k.	not to cause, permit, or authorize the modification, creation of derivative works, or translation of this App without PRO365’s express permission;
<br />l.	that you are solely responsible for your interactions with other users and users through the App;
<br />m.	that you will not collect other users’ and users' content or information, or otherwise access the App, using automated means (such as harvesting bots, robots, spiders or scraping techniques) or otherwise, without our prior written permission;
<br />n.	that any content you upload or post to the App, including instant messages sent and received through the App’s instant messaging service, will be considered non-confidential and non-proprietary and that such content may be viewable by any users of the App (whether registered or unregistered). You will own your content, but you hereby grant us, our licensee’s and any third parties and other users of the App (each as we determine) a perpetual, irrevocable, royalty-free, transferrable, sub-licensable, worldwide license to use, store and copy that content and to distribute it and make it available to third parties via any and all media, including, without limitation, the right for us to upload and make available and to authorize third parties to upload and make available such content on third-party sites and services, including social media applications and channels such as Facebook, Twitter, Pinterest, YouTube, Instagram and on the Publisher’s own websites;
<br />o.	that you waive any moral rights or equivalent rights in any jurisdiction in relation to any content that you upload or post to the App and that we can use such content without referencing you as the author of such work and that we can adapt and amend such content in our sole discretion;
<br />p.	to compensate and defend us fully against any claims or legal proceedings brought against us by any person as a result of your breach of these Terms;
<br />q.	to keep your password secure at all times and not to disclose your password to any other person;
<br />r.	not to allow any other person to use or access your account; and
<br />s.	to comply with all laws applicable to you.
<br />
                    <br />Employer and Recruiter-Specific Rules
<br />
                    <br />You agree that you shall only use the App and the Materials, Software, and Content for your own internal business use seeking candidates for employment. You also agree that you shall not violate state and federal laws by discriminating on the basis of any characteristic protected by applicable law, including, without limitation, race, color, religion (creed), sex, age, national origin, disability, marital status, sexual orientation, or military status while using Materials, Software, or Content to make employment decisions. All job postings must comply with the applicable laws and regulations of the state where the job is to be performed, including laws relating to labor and employment, equal employment opportunity and employment eligibility. You may not post jobs that contain false information or solicits employees by misrepresentation of the terms of employment or any intentional misrepresentation.
<br />
                    <br />PRIVACY
<br />You agree that PRO365 may collect and use technical data and related information—including but not limited to technical information about your device, system and application software, and peripherals—that is gathered periodically to facilitate the provision of software updates, support, and other services to you (if any) related to the App. PRO365 may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you. Your use of our App is subject to our Privacy Policy.  Please read it so that you understand the parameters of privacy when using our App. Keep in mind, however, that the information you make available as part of your public profile, and the information that you voluntarily post on this App, are public and are not protected under our Privacy Policy. We may also collect personal information, which is subject to our Privacy Policy.
<br />
                    <br />We reserve the right to monitor some, all, or none of the areas of this App for adherence to these Terms of Use. You acknowledge that by providing you with the ability to post information and sell Products on this App, we are acting as a passive conduit for distribution and we are not undertaking any obligation or liability relating to any postings or activities on this App.
<br />
                    <br />THIRD-PARTY INFORMATION
<br />Please be aware that when you are on the App, you could be directed to websites that are beyond PRO365's control. PRO365 is not responsible for the content of any linked site or any link contained in a linked site owned or controlled by a third party. PRO365 reserves the right to terminate any link or linking program at any time. PRO365 provides such links only as a convenience to you. PRO365 has not endorsed, tested or verified any information, programs, companies, or products on sites to which it links. If you decide to access any third-party sites linked to this App and/or provide any personally identifiable information to such, you do so subject to the terms and policies applicable to those sites and entirely at your own risk.
<br />
                    <br />PRO365 may provide you information from third parties as part of this App. Unless expressly stated, PRO365 does not represent, endorse or guarantee the accuracy, completeness, timeliness, reliability or suitability of any information provided by third parties. You agree that PRO365 is not responsible for third-party information accessible through this App, including opinions, advice, statements, recipes or other health and wellness information and advertisements, and that you use such information at your own risk.
<br />
                    <br />TERRITORY
<br />PRO365 operates this App from its office within the United States. PRO365 makes no representation that content and materials on this App are legal or appropriate for use from outside the United States. Please keep in mind that this App may not conform with the laws of your country. If you access this App from outside the United States, you do so at your own risk. You may not use the App in violation of United States export laws and regulations.
<br />
                    <br />DISCLAIMER OF WARRANTIES
<br />THE APP, INCLUDING ALL MATERIALS, SERVICES, SOFTWARE, INFORMATION AND OTHER CONTENT AVAILABLE ON AND/OR THROUGH THE APP, IS PROVIDED BY PRO365 "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CONTINUED AVAILABILITY, QUALITY, ACCURACY AND/OR SYSTEM COMPATIBILITY. IN ADDITION, NO WARRANTIES SHALL ARISE FROM ANY COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE.
<br />
                    <br />BY USING THIS APP, YOU ASSUME ALL OF THE RISKS ASSOCIATED WITH SUCH USE, AND YOU EXPRESSLY RELEASE PRO365, AND ITS THIRD-PARTY INFORMATION AND SERVICE PROVIDERS, FROM ANY AND ALL LIABILITY, CLAIMS AND/OR LOSSES ARISING FROM OR CONNECTED WITH SUCH RISKS. YOU ACKNOWLEDGE THAT: THE APP COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES OR TYPOGRAPHICAL ERRORS; THE APP MAY BECOME INOPERABLE OR OTHERWISE UNAVAILABLE FOR PERIODS OF TIME; MATERIALS AND/OR SERVICES ON THE APP MAY BE OR BECOME OUT OF DATE AND PRO365 MAKES NO COMMITMENT TO UPDATE SUCH MATERIALS OR SERVICES. PRO365 ASSUMES NO RESPONSIBILITY FOR ERRORS OR OMISSIONS IN THE INFORMATION, DOCUMENTS, SOFTWARE, MATERIALS AND/OR SERVICES WHICH ARE REFERENCED BY OR LINKED TO THE APP. REFERENCES TO THIRD PARTIES, THEIR SERVICES AND PRODUCTS, ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED.
<br />
                    <br />LIMITATION OF LIABILITY
<br />REGARDLESS OF THE FORM OF ACTION OR THEORY OF RECOVERY, AND EXCEPT WHERE PROHIBITED BY LAW, IN NO EVENT SHALL PRO365, ITS AFFILIATES, ITS LICENSORS AND/OR ITS SERVICE PROVIDERS BE LIABLE OR RESPONSIBLE TO YOU IN ASSOCIATION WITH THE APP, OR YOUR USE THEREOF, FOR ANY: (A) INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES, EVEN IF THEY ARE AWARE OF THE POSSIBILITY OF SUCH DAMAGES; (B) LOST PROFITS, LOST REVENUE, LOSS OF DATA, LOST EXPECTANCY OR BUSINESS INTERRUPTIONS; OR (C) TOTAL DAMAGES IN EXCESS OF (IN THE AGGREGATE) FIFTY DOLLARS ($50.00).  SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU. ANY CLAIM RELATED TO THE SERVICE MUST BE INITIATED WITHIN ONE (1) YEAR OF THE DATE OF THE EVENT THAT GAVE RISE TO THE CLAIM.
<br />
                    <br />PRO365 SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY DELAY OR FAILURE IN THE APP, OR ANY COMPONENT THEREOF, OR YOUR ABILITY TO ACCESS OR USE THE APP, RESULTING FROM CAUSES OUTSIDE OF PRO365’S CONTROL, INCLUDING, WITHOUT LIMITATION, POWER OUTAGES, ACCIDENTS, STRIKES, FIRES, WAR OR ACTS OF GOD.
<br />
                    <br />INDEMNITY
<br />You agree to indemnify and hold PRO365, and its parents, subsidiaries, affiliates, officers, agents, co-branders, partners, licensors, and employees, harmless from any alleged claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your Content or Products, your use of this App, your connection to this App, your violation of these Terms of Use or any other applicable policy, your violation of any rights of another, or breach of any representation or warranty made by you under these Terms of Use, whether you are a registered user or not. You are solely responsible for your actions when using this App, including, but not limited to, costs incurred for providing or receiving Products.
<br />
                    <br />TERMINATION
<br />PRO365 may terminate your access to this App at any time and for any reason without prior notification. Additionally, PRO365 may modify this App at any time, and continuing to use the App constitutes your agreement to such modifications. We may also, in our sole discretion and at any time, with or without notice, discontinue this App or any portion of it, restrict the time the App is available, or restrict the amount of use permitted. You agree that we may terminate or restrict your access to this App under these Terms of Use or under any other applicable policy without prior notice, and acknowledge and agree that we may immediately deactivate or delete your Account, as applicable, and all related information and files. We reserve the right to bar any further access to such files and this App. You agree that we will not be liable to you or any thirdparty for any termination of your Account or access to this App. Provisions that survive termination of these Terms of Use are those relating to ownership, limitations of liability, indemnification and others which by their nature are intended to survive.
<br />
                    <br />APPLICABLE LAW, SEVERABILITY AND WAIVER
<br />In order to ensure consistency in the interpretation and enforcement of these Terms of Use and PRO365's rights in the App, these Terms of Use will be governed exclusively by Michigan law and controlling U.S. federal law, without regard to the choice or conflicts of law provisions of any jurisdiction. You expressly agree that any litigation or dispute arising between you and PRO365 related, in any way, to the App and/or these Terms of Use, and/or any and all disputes, actions, claims, or causes of action related thereto, shall be initiated and maintained before the Circuit Court for Macomb County, Michigan or the United States District Court for the Eastern District of Michigan.  You expressly consent and irrevocably submit to the exclusive personal jurisdiction and venue of such courts.
<br />
                    <br />If any provision of these Terms of Use is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision shall be modified to the extent necessary to be valid and enforceable, and all other provisions of these Terms of Use shall remain in full force and effect.
<br />
                    <br />The failure of PRO365 to enforce any right or provision in these Terms of Use shall not constitute a waiver of such right or provision unless acknowledged and agreed to by PRO365 in writing. No waiver shall be implied from a failure of either party to exercise a right or remedy. In addition, no waiver of a party's right or remedy will affect the other provisions of these Terms of Use.
<br />
                    <br />DIGITAL MILLENNIUM COPYRIGHT NOTICES
<br />Notices and demands made pursuant to the Digital Millennium Copyright Act Section 512(c), 17 U.S.C. § 512(c) should be submitted to:
<br />
                    <br />[fill in name]
<br />PRO365, LLC
<br />51245 Filomena Dr.
<br />Shelby Township, MI 48315, USA
<br />
                    <br />SEVERABILITY
<br />If any one of our terms is deemed invalid, void, or unenforceable for any reason, that term shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.
<br />
                    <br />ADDITIONAL TERMS FOR USERS ACQUIRING THE APP FROM APPLE, INC.
<br />If you download the App from Apple, you acknowledge and agree that PRO365, not Apple, is solely responsible for the App, any applicable warranties and support thereunder, any claims that may arise as a result of your use of the App or products purchased or sold pursuant to the App, and any third-party claims that the App infringed on the intellectual property rights of another. Additionally, you represent and warrant that you are not located in a country that is subject to a U.S. government embargo or that has been designated by the U.S. government as a “terrorist supporting” countr, and that you are not listed on any U.S. government list of prohibited or restricted parties.  Apple, and Apple’s subsidiaries, are third-party beneficiaries of these Terms of Use, and upon your acceptance of these Terms of Use, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms of Use against you as a third-party beneficiary thereof.
<br />
                    <br />You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support service with regard to the App. However, in the event of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the App to you. To the maximum extent permitted by applicable law, Apple has and will have no other warranty obligation whatsoever with respect to the App, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be PRO365’s sole responsibility. You acknowledge that your use of the App may enable SMS or text messages. You, not PRO365, are responsible for any changes associated with such functionality.
<br />
                    <br />PRO365, and not Apple, is responsible for addressing any claims made by you or a third party relating to the App or your possession and/or use of the App, including, but not limited to: (i) product liability claims; (ii) any claims that the Licensed Application fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. You acknowledge that, in the event of any third-party claim that the App or your possession and use of the App infringes that third-party’s intellectual property rights, PRO365, not Apple, is solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.
<br />
                    <br />You understand and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of these Terms of Use.  Upon your acceptance of the terms and conditions of these Terms of Use, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms of Use against you as a third-party beneficiary hereof.
<br />
                    <br />CONTACTING US
<br />If you have additional questions, please contact us:
<br />
                    <br />PRO365, LLC
<br />51245 Filomena Dr.
<br />Shelby Township, MI 48315, USA
<br />Email: support@365.com
<br />
                    <br />
                    <br />Effective Date: 9/20/2019
<br />
                    <br />
                    <br />                </p>
            </div>
        </Modal>

    )
}

export default Terms