import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppStores from '../../../components/appStores';
import '../../../style.css';

const ApplicantLandingPage = ({ switchMode, switchView, isIOS }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      switchView()
    }, 15000)

    return (() => clearTimeout(timer))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="showcase">
      <div className="video-container">
        <video src="images/applicant.mp4" autoPlay muted loop playsInline={isIOS}></video>
      </div>
      <div className="showcase-top">
        <img src="images/pro365-logo.png" alt="Pro365 Logo" />
        {/* <button className="btn-login" onClick={login}>Login</button> */}
      </div>

      <div className="showcase-content">
        <h1>
          Perfect Match For Your Career
        </h1>
        <h2>
          Take the next big step without digging through the trenches. 
          <br />
          Find quality matching jobs quicker.
        </h2>
        <div className="btnOptions">
          <button className="btn-active">Applicant</button>
          <Link to='/employer'>
            <button className="btn">Employer</button>
          </Link>
          
        </div>
      </div>
      <AppStores mode='applicant' />
    </div>
  )
}

export default ApplicantLandingPage